import { concat, difference, reduce } from 'ramda';
export var CallbackActionType;
(function (CallbackActionType) {
    CallbackActionType["AddBlocked"] = "Callbacks.AddBlocked";
    CallbackActionType["AddExecuted"] = "Callbacks.AddExecuted";
    CallbackActionType["AddExecuting"] = "Callbacks.AddExecuting";
    CallbackActionType["AddPrioritized"] = "Callbacks.AddPrioritized";
    CallbackActionType["AddRequested"] = "Callbacks.AddRequested";
    CallbackActionType["AddStored"] = "Callbacks.AddStored";
    CallbackActionType["AddWatched"] = "Callbacks.AddWatched";
    CallbackActionType["RemoveBlocked"] = "Callbacks.RemoveBlocked";
    CallbackActionType["RemoveExecuted"] = "Callbacks.RemoveExecuted";
    CallbackActionType["RemoveExecuting"] = "Callbacks.RemoveExecuting";
    CallbackActionType["RemovePrioritized"] = "Callbacks.RemovePrioritized";
    CallbackActionType["RemoveRequested"] = "Callbacks.RemoveRequested";
    CallbackActionType["RemoveStored"] = "Callbacks.RemoveStored";
    CallbackActionType["RemoveWatched"] = "Callbacks.RemoveWatched";
})(CallbackActionType || (CallbackActionType = {}));
export var CallbackAggregateActionType;
(function (CallbackAggregateActionType) {
    CallbackAggregateActionType["AddCompleted"] = "Callbacks.Completed";
    CallbackAggregateActionType["Aggregate"] = "Callbacks.Aggregate";
})(CallbackAggregateActionType || (CallbackAggregateActionType = {}));
const DEFAULT_STATE = {
    blocked: [],
    executed: [],
    executing: [],
    prioritized: [],
    requested: [],
    stored: [],
    watched: [],
    completed: 0
};
const transforms = {
    [CallbackActionType.AddBlocked]: concat,
    [CallbackActionType.AddExecuted]: concat,
    [CallbackActionType.AddExecuting]: concat,
    [CallbackActionType.AddPrioritized]: concat,
    [CallbackActionType.AddRequested]: concat,
    [CallbackActionType.AddStored]: concat,
    [CallbackActionType.AddWatched]: concat,
    [CallbackActionType.RemoveBlocked]: difference,
    [CallbackActionType.RemoveExecuted]: difference,
    [CallbackActionType.RemoveExecuting]: difference,
    [CallbackActionType.RemovePrioritized]: difference,
    [CallbackActionType.RemoveRequested]: difference,
    [CallbackActionType.RemoveStored]: difference,
    [CallbackActionType.RemoveWatched]: difference
};
const fields = {
    [CallbackActionType.AddBlocked]: 'blocked',
    [CallbackActionType.AddExecuted]: 'executed',
    [CallbackActionType.AddExecuting]: 'executing',
    [CallbackActionType.AddPrioritized]: 'prioritized',
    [CallbackActionType.AddRequested]: 'requested',
    [CallbackActionType.AddStored]: 'stored',
    [CallbackActionType.AddWatched]: 'watched',
    [CallbackActionType.RemoveBlocked]: 'blocked',
    [CallbackActionType.RemoveExecuted]: 'executed',
    [CallbackActionType.RemoveExecuting]: 'executing',
    [CallbackActionType.RemovePrioritized]: 'prioritized',
    [CallbackActionType.RemoveRequested]: 'requested',
    [CallbackActionType.RemoveStored]: 'stored',
    [CallbackActionType.RemoveWatched]: 'watched'
};
const mutateCompleted = (state, action) => ({
    ...state,
    completed: state.completed + action.payload
});
const mutateCallbacks = (state, action) => {
    const transform = transforms[action.type];
    const field = fields[action.type];
    return !transform || !field || action.payload.length === 0
        ? state
        : {
            ...state,
            [field]: transform(state[field], action.payload)
        };
};
export default (state = DEFAULT_STATE, action) => reduce((s, a) => {
    if (a === null) {
        return s;
    }
    else if (a.type === CallbackAggregateActionType.AddCompleted) {
        return mutateCompleted(s, a);
    }
    return mutateCallbacks(s, a);
}, state, action.type === CallbackAggregateActionType.Aggregate
    ? action.payload
    : [action]);
