import { assoc, assocPath, dissoc } from 'ramda';
const setJob = (job, state) => assoc(job.jobId, job, state);
const removeJob = (jobId, state) => dissoc(jobId, state);
const setOutdated = (jobId, state) => assocPath([jobId, 'outdated'], true, state);
export default function (state = {}, action) {
    switch (action.type) {
        case 'ADD_CALLBACK_JOB':
            return setJob(action.payload, state);
        case 'REMOVE_CALLBACK_JOB':
            return removeJob(action.payload.jobId, state);
        case 'CALLBACK_JOB_OUTDATED':
            return setOutdated(action.payload.jobId, state);
        default:
            return state;
    }
}
