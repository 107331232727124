import { concat, filter, groupBy, isNil, partition, reduce, toPairs } from 'ramda';
import { aggregateCallbacks, removeStoredCallbacks } from '../actions/callbacks';
import { getPendingCallbacks } from '../utils/callbacks';
const observer = {
    observer: ({ dispatch, getState }) => {
        const { callbacks } = getState();
        const pendingCallbacks = getPendingCallbacks(callbacks);
        const { callbacks: { stored } } = getState();
        const [nullGroupCallbacks, groupCallbacks] = partition(cb => isNil(cb.executionGroup), stored);
        const executionGroups = groupBy(cb => cb.executionGroup, groupCallbacks);
        const pendingGroups = groupBy(cb => cb.executionGroup, filter(cb => !isNil(cb.executionGroup), pendingCallbacks));
        const dropped = reduce((res, [executionGroup, executionGroupCallbacks]) => !pendingGroups[executionGroup]
            ? concat(res, executionGroupCallbacks)
            : res, [], toPairs(executionGroups));
        dispatch(aggregateCallbacks([
            nullGroupCallbacks.length
                ? removeStoredCallbacks(nullGroupCallbacks)
                : null,
            dropped.length ? removeStoredCallbacks(dropped) : null
        ]));
    },
    inputs: ['callbacks.stored', 'callbacks.completed']
};
export default observer;
