import { any, filter, forEach, map, path } from 'ramda';
export default class StoreObserver {
    _store;
    _unsubscribe;
    _observers = [];
    constructor(store) {
        this.__init__(store);
    }
    observe = (observer, inputs) => {
        if (typeof observer === 'function') {
            if (!Array.isArray(inputs)) {
                throw new Error('inputs must be an array');
            }
            this.add(observer, inputs);
            return () => this.remove(observer);
        }
        this.add(observer.observer, observer.inputs);
        return () => this.remove(observer.observer);
    };
    setStore = (store) => {
        this.__finalize__();
        this.__init__(store);
    };
    __finalize__ = () => this._unsubscribe?.();
    __init__ = (store) => {
        this._store = store;
        if (store) {
            this._unsubscribe = store.subscribe(this.notify);
        }
        forEach(o => (o.lastState = null), this._observers);
    };
    add = (observer, inputs) => this._observers.push({
        inputPaths: map(p => p.split('.'), inputs),
        lastState: null,
        observer,
        triggered: false
    });
    notify = () => {
        const store = this._store;
        if (!store) {
            return;
        }
        const state = store.getState();
        const triggered = filter(o => !o.triggered &&
            any(i => path(i, state) !== path(i, o.lastState), o.inputPaths), this._observers);
        forEach(o => (o.triggered = true), triggered);
        forEach(o => {
            o.lastState = store.getState();
            o.observer(store);
            o.triggered = false;
        }, triggered);
    };
    remove = (observer) => this._observers.splice(this._observers.findIndex(o => observer === o.observer, this._observers), 1);
}
